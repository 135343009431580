<template>
  <div
    ref="assets_container"
    class="container"
  >
    <div
      class="d-flex flex-column"
    >
      <div
        ref="search_bar"
        class="pb-2 d-flex flex-column"
      >
        <div class="pt-4">
          <el-form
            ref="condition"
            :inline="true"
            :model="conditions"
          >
            <el-form-item prop="Session">
              <el-select
                v-model="conditions.Session"
                placeholder="Session"
                clearable
                collapse-tags
              >
                <el-option
                  v-for="session in options.sessions"
                  :key="session"
                  :label="session"
                  :value="session"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="LeadAssetTherapeuticArea">
              <el-select
                v-model="conditions.LeadAssetTherapeuticArea"
                placeholder="Lead asset therapeutic area"
                clearable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="area in options.areas"
                  :key="area"
                  :label="area"
                  :value="area"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="Demand">
              <el-select
                v-model="conditions.Demand"
                placeholder="Demand"
                clearable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="demand in options.demands"
                  :key="demand"
                  :label="demand"
                  :value="demand"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="Type">
              <el-select
                v-model="conditions.Type"
                placeholder="Type"
                clearable
              >
                <el-option
                  v-for="type in options.types"
                  :key="type"
                  class="text-capitalize"
                  :label="type"
                  :value="type"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="search()"
              >
                {{ $t('search') }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div
        ref="table_container"
        class="table-container flex-1"
      >
        <el-table
          :data="assetVideoList"
          stripe
          sticky
          :sticky-offset-top="0"
          @sort-change="sortSearch"
        >
          <el-table-column
            prop="CompnyName"
            label="Company Name"
            width="200"
          >
            <template slot-scope="{row}">
              {{ row.CompnyName }}
              <el-tooltip
                v-if="row.VideoName"
                class="item"
                effect="dark"
                content="View Company Presentation"
                placement="right"
              >
                <router-link
                  class="link"
                  :to="{name:'Player',params:{path:row.VideoName}}"
                  target="_blank"
                >
                  <i class="fas el-icon-fa-play-circle" />
                </router-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="CompanyIntro"
            label="Company Intro"
          />
          <el-table-column
            prop="Session"
            label="Session"
            width="150"
          />
          <el-table-column
            prop="LeadAssetTherapeuticArea"
            label="Lead Asset Therapeutic Area"
            width="150"
          />
          <el-table-column
            prop="Demand"
            label="Demand"
            width="150"
          />
          <el-table-column
            prop="Type"
            label="Type"
            width="90"
            class-name="text-capitalize"
            align="center"
          />
        </el-table>
      </div>
      <div
        ref="pagination"
        class="text-center pt-2 pb-2"
      >
        <el-pagination
          layout="prev, pager, next"
          :current-page.sync="page.current"
          :page-size="page.size"
          :total="page.total"
          @current-change="search()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import * as AssetsAPI from '@/api/assets'
import ElementTableHorizontalScrollbar from '@/mixin/ElementTableHorizontalScrollbar'

export default @Component({
  mixins: [ElementTableHorizontalScrollbar]
})
class Assets extends Vue {
  options = {
    sessions: [
      'Big Pharma',
      'CNS/Cardiovascular',
      'Fundraising',
      'GI/Eye/Infectious',
      'Medtech Innovation session',
      'Oncology 1',
      'Oncology 2',
      'Oncology/Immunotherapy',
      'Opening Speech',
      'Platform technology/ Women Health/Other',
      'South Korea Bio-Innovation Day'
    ],
    areas: [
      'Cardiovascular',
      'Digestive system',
      'Diseases of the eye',
      'Diseases of the nervous system',
      'Drug delivery/formulation technology',
      'Endocrine, nutritional and metabolic diseases',
      'Infectious and parasitic diseases',
      'Musculoskeletal system and connective tissue',
      'Neoplasms / cancer / oncology',
      'Other',
      'Respiratory system',
      'Skin and subcutaneous tissue',
      '肿瘤'
    ],
    demands: ['Licensing', 'Fundraising'],
    types: ['roadshow', 'speech']
  }

  conditions = {
    Session: null,
    LeadAssetTherapeuticArea: [],
    Demand: [],
    Type: null
  }

  page = {
    current: 1,
    size: 20,
    total: 0
  }

  sort = {
    column: '',
    order: ''
  }

  assetVideoList = []

  mounted () {
    this.search()
  }

  sortSearch ({
    column,
    prop,
    order
  }) {
    this.sort = {
      column: prop,
      order: {
        ascending: 'ASC',
        descending: 'DESC'
      }[order]
    }
    this.search()
  }

  async search (resetForm = false) {
    if (resetForm) {
      this.$refs.condition.resetFields()
      this.page.current = 1
    }
    const {
      current: page,
      size
    } = this.page
    const data = await Promise.all([
      AssetsAPI.getCompanyVideoList({
        ...this.conditions,
        sort: this.sort,
        page: page - 1,
        size
      }), AssetsAPI.getCompanyVideoListSize({ ...this.conditions })])
    this.assetVideoList = data[0]
    this.page.total = data[1]
  }

  contact (data) {
    this.$router.push({
      name: 'Contact',
      params: {
        code: data.asset_code
      },
      query: {
        from: this.$route.meta.statistical
      }
    })
  }

  copyShareUrl () {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.shareUrl)
      this.$notify.success({
        message: this.$t('copy_share_url_success')
      })
    }
  }

  emptyFormatter (row, column) {
    const value = row[column.property]
    if (value === '0' || !value) {
      return '-'
    } else {
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.introduction {
  padding: 1rem 0;
  line-height: 1.5;
  font-size: 14px;
  color: #303133;

  p {
    margin: 0.5rem 0;
  }
}

.line {
  position: relative;

  &:before {
    content: '';
    width: 50%;
    height: 1px;
    position: absolute;
    left: 50%;
    background: #dcdfe6;
    transform: translateX(-50%);
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.link {
  color: #db9620;
  text-decoration: underline #db9620;
}

</style>
