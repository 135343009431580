import request from '@/utils/request'
import qs from 'qs'
import _ from 'lodash'
import { options } from '@/api/cache'

export function getList (condition) {
  const params = _.pickBy({
    _where: getQueryCondition(condition),
    _sort: condition.sort.column && condition.sort.order
      ? `${condition.sort.column}:${condition.sort.order}`
      : 'status:DESC, date:DESC',
    _start: (condition.page || 0) * (condition.size || 20),
    _limit: condition.size || 20
  }, _.identity)

  return request.get('/assets', {
    params,
    paramsSerializer: qs.stringify
  })
}

export function getOptions () {
  // return request.get('/assets/options')
  return options
}

export function getSize (condition) {
  return request.get('/assets/count', {
    params: { _where: getQueryCondition(condition) },
    paramsSerializer: qs.stringify
  })
}

function getQueryCondition (condition) {
  const where = [
    {
      available: 1
    }]
  if (condition.type) {
    where.push({
      session_contains: {
        global_licensing_opportunities: 'Global Licensing',
        china_innovation: 'China Licensing',
        china_asset_m_a: 'China Asset M&A',
        fundraising: 'Fundraising'
      }[condition.type]
    })
  }
  if (condition.demand_type) {
    where.push({
      demand_type_eq: condition.demand_type
    })
  }
  if (condition.code) {
    where.push({
      asset_code_contains: condition.code
    })
  }
  if (condition.keywords) {
    where.push({
      _or: [
        { all_text_contains: condition.keywords },
        { asset_description_contains: condition.keywords }
      ]
    })
  }
  if (condition.disease?.length) {
    where.push({
      _or: condition.disease.map(item => ({ disease_contains: item }))
    })
  }

  if (condition.stage?.length) {
    where.push({
      _or: condition.stage.map(item => ({ phase_contains: item }))
    })
  }
  if (condition.created_at?.length) {
    where.push({
      _and: [
        {
          created_at_gte: condition.created_at[0]
        },
        {
          created_at_lte: condition.created_at[1]
        }
      ]
    })
  }
  return where
}

export function getVideoURL (path) {
  return request.get('assets/video', {
    params: { path }
  })
}

export function getCompanyVideoList (condition) {
  const params = _.pickBy({
    _where: getVideoQueryCondition(condition),
    _start: (condition.page || 0) * (condition.size || 20),
    _limit: condition.size || 20
  }, _.identity)

  return request.get('/asset-company-videos', {
    params,
    paramsSerializer: qs.stringify
  })
}

export function getCompanyVideoListSize (condition) {
  return request.get('/asset-company-videos/count', {
    params: { _where: getVideoQueryCondition(condition) },
    paramsSerializer: qs.stringify
  })
}

function getVideoQueryCondition (condition) {
  const where = []

  if (condition.Session) {
    where.push({
      Session_eq: condition.Session
    })
  }

  if (condition.Type) {
    where.push({
      Type_eq: condition.Type
    })
  }

  if (condition.Demand?.length) {
    where.push({
      _or: condition.Demand.map(item => ({ Demand_contains: item }))
    })
  }
  if (condition.LeadAssetTherapeuticArea?.length) {
    where.push({
      _or: condition.LeadAssetTherapeuticArea.map(
        item => ({ LeadAssetTherapeuticArea_contains: item }))
    })
  }

  return where
}

export function apply (data) {
  return request.post('asset-applies/commit', data)
}
