<template>
  <div class="player-container">
    <video
      v-if="url"
      class="player"
      :src="url"
      controls
      controlsList="nodownload"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getVideoURL } from '@/api/assets'

export default @Component({})
class Player extends Vue {
  @Prop({ required: true }) path
  url = null

  mounted () {
    this.getVideoURL()
  }

  async getVideoURL () {
    const { url } = await getVideoURL(this.path)
    this.url = url
  }
}
</script>

<style lang="scss" scoped>
.player-container {
  width: 100vw;
  height: 100vh;

  .player {
    width: 100%;
    height: 100%;
  }
}
</style>
