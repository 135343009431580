let el
let tableBodyWrapDom
let tableBodyDom

function handle () {
  if (!el) return
  // top为dom上侧距离可视窗口顶部的值
  const { top: tableBodyDomTop } = tableBodyWrapDom.getBoundingClientRect()
  if (tableBodyDomTop > window.innerHeight || tableBodyWrapDom.classList.contains('is-scrolling-none')) {
    // 此时列表在可视窗口的下侧不可见区域，因此不做任何修改
    tableBodyWrapDom.style.height = 'unset'
    tableBodyWrapDom.style.marginBottom = 'unset'
  } else {
    // 窗口高度 - 列表距顶部值 且 不超过自身实际值
    const wrapHeight = Math.min(window.innerHeight - tableBodyDomTop, tableBodyDom.offsetHeight)
    tableBodyWrapDom.style.height = wrapHeight + 'px'
    // 需要用marginBottom填充，以保持列表原有高度，避免页面的纵向滚动条变化导致页面滚动的不流畅
    // 可以通过注释这一行代码观察其效果差异
    tableBodyWrapDom.style.marginBottom = (tableBodyDom.offsetHeight - wrapHeight) + 'px'
  }
}

export default {
  mounted () {
    el = this.$el // 当前组件的Dom对象，避免操作到本组件之外的Dom
    tableBodyWrapDom = el.querySelector('.el-table__body-wrapper')
    tableBodyDom = el.querySelector('.el-table__body')
    // 监听事件
    document.addEventListener('scroll', handle)
    window.addEventListener('resize', handle)
  },
  destroyed () {
    // 在组件销毁时取消监听
    document.removeEventListener('scroll', handle)
    window.removeEventListener('resize', handle)
  },
  watch: {
    _list () {
      // 当列表数据源发生变化时，再次触发
      this.$nextTick(handle)
    }
  }
}
