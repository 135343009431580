export const options = {
  status: '0000',
  message: 'success',
  data: {
    diseases: [
      [
        'Auto-immnue',
        '自身免疫'
      ],
      [
        'Infectious disease',
        '传染疾病'
      ],
      [
        'Immune disorders',
        '免疫疾病'
      ],
      [
        'Diseases of the eye',
        '眼科'
      ],
      [
        'Neoplasms / cancer / oncology',
        '肿瘤'
      ],
      [
        'Digestive system',
        '消化系统'
      ],
      [
        'Cardiovascular',
        '心脑血管'
      ],
      [
        'Diseases of the blood and blood-forming organs; immune disorders',
        '血液和造血器官疾病； 免疫疾病'
      ],
      [
        'Diseases of the nervous system',
        '神经系统疾病'
      ],
      [
        'Drug delivery/formulation technology',
        '药物输送/配制技术'
      ],
      [
        'Endocrine, nutritional and metabolic diseases',
        '内分泌，营养和代谢性疾病'
      ],
      [
        'Genitourinary system',
        '泌尿生殖系统'
      ],
      [
        'Infectious and parasitic diseases',
        '传染病和寄生虫病'
      ],
      [
        'Musculoskeletal system and connective tissue',
        '肌肉骨骼系统和结缔组织'
      ],
      [
        'Respiratory system',
        '呼吸系统'
      ],
      [
        'Skin and subcutaneous tissue',
        '皮肤和皮下组织'
      ],
      [
        'Other',
        '其他'
      ]
    ],
    phases: [
      'Lead discovery/optimization',
      'Preclinical testing',
      'IND',
      'Phase 1 Ongoing',
      'Phase 1 Completed',
      'Phase 2 Ongoing',
      'Phase 2 Completed',
      'Phase 3 Ongoing',
      'Phase 3 Completed',
      'Pre registration(NDA sumbited)',
      'Registered(FDA, CE, CFDA, etc)',
      'On market'
    ]
  }
}
