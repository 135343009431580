import request from '@/utils/request'

export function upload (file) {
  const formData = new FormData()
  formData.append('files', file)
  return request.post('/upload', formData)
}

export function debug (message = {}, extra = {}, type = 'info') {
  return request.post(process.env.VUE_APP_API_DEBUG_URL, {
    type, message, extra
  })
}
