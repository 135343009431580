<template>
  <div
    ref="assets_container"
    class="container"
  >
    <div
      class="d-flex flex-column"
    >
      <div class="introduction">
        <p>
          【<span class="text-primary">ACCESS CHINA</span>】This Licensing Opportunities Table summarizes global
          pharmaceutical assets available for out-licensing, and it also including China Biotech Innovation assets
          (Out-Licensing Opportunities).
        </p>
        <p>
          If you are interested in any asset, please submit your interest by clicking the “contact” button. After
          approval by the asset owner, additional information will be provided to you.
        </p>
      </div>
      <div class="line" />
      <div
        ref="search_bar"
        class="pb-2 d-flex flex-column"
      >
        <div class="pt-4">
          <el-form
            ref="condition"
            :inline="true"
            :model="conditions"
          >
            <el-form-item prop="disease">
              <el-select
                v-model="conditions.disease"
                style="width: 300px"
                :placeholder="$t('disease_tip')"
                clearable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="disease in options.diseases"
                  :key="disease[0]"
                  :label="$t(disease[0])"
                  :value="disease[0]"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="stage">
              <el-select
                v-model="conditions.stage"
                :placeholder="$t('stage_tip')"
                clearable
                multiple
                style="width: 280px;"
                collapse-tags
              >
                <el-option
                  v-for="stage in options.phases"
                  :key="stage"
                  :label="stage"
                  :value="stage"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                v-model="conditions.code"
                :placeholder="$t('code')"
              />
            </el-form-item>
            <el-form-item prop="keywords">
              <el-input
                v-model="conditions.keywords"
                :placeholder="$t('keywords')"
              />
            </el-form-item>
            <el-form-item prop="created_at">
              <el-date-picker
                v-model="conditions.created_at"
                style="width: 280px;"
                type="daterange"
                unlink-panels
                :start-placeholder="$tc('start_date')"
                :end-placeholder="$tc('end_date')"
                :picker-options="pickerOptions"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="search()"
              >
                {{ $t('search') }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div
        ref="table_container"
        class="table-container flex-1"
      >
        <el-table
          :data="assetsList"
          stripe
          sticky
          :sticky-offset-top="tableOffsetTop"
          @sort-change="sortSearch"
        >
          <el-table-column
            width="100"
            align="center"
            :label="$t('created_at')"
            prop="created_at"
            sortable="custom"
          >
            <template v-slot="{row}">
              {{ row.created_at | moment('YYYY-MM-DD') }}
            </template>
          </el-table-column>
          <el-table-column
            prop="asset_code"
            :label="$t('code')"
            width="120"
          >
            <template v-slot="{row}">
              <div class="d-flex align-items-center">
                {{ row.asset_code }}
                <img
                  v-if="row.status === 'Active'"
                  class="hot"
                  src="@/assets/images/new.svg"
                  alt=""
                >
              </div>
              <div>
                <el-tooltip
                  v-if="row.has_video"
                  effect="dark"
                  :content="$t('video_tooltip')"
                  placement="right"
                >
                  <el-button type="text">
                    <i
                      class="el-icon-video-camera ml-1"
                      style="font-size: 16px;"
                    />
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="disease_sort"
            :label="$t('disease')"
            width="200"
            sortable="custom"
          >
            <template v-slot="{row}">
              <div
                v-for="item in getJSONData(row.disease)"
                :key="item[0]"
              >
                {{ $t(item[0]) }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="moa"
            :label="$t('moa')"
            width="180"
            :formatter="emptyFormatter"
          />
          <el-table-column
            prop="asset_type"
            :label="$t('type')"
            width="120"
            align="center"
            sortable="custom"
            :formatter="emptyFormatter"
          />
          <el-table-column
            prop="phase_sort"
            :label="$t('stage')"
            width="150"
            sortable="custom"
          >
            <template v-slot="{row}">
              <div
                v-for="item in getJSONData(row.phase)"
                :key="item"
              >
                {{ item }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('asset_description')"
            :min-width="320"
          >
            <template v-slot="{row}">
              <div style="white-space: pre-line;">
                <text-highlight :queries="[conditions.keywords]">
                  {{ row.lead_asset_description || row.asset_description || '-' }}
                </text-highlight>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="90"
            align="center"
            :label="$t('right_available')"
            prop="right_available"
            :formatter="emptyFormatter"
          />
          <el-table-column
            width="100"
            align="center"
          >
            <template v-slot="{row}">
              <el-button
                type="primary"
                @click="contact(row)"
              >
                {{ $t('contact') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        ref="pagination"
        class="text-center pt-2 pb-2"
      >
        <el-pagination
          layout="prev, pager, next"
          :current-page.sync="page.current"
          :page-size="page.size"
          :total="page.total"
          @current-change="search()"
        />
      </div>
      <div class="line" />
      <div class="introduction">
        <p>
          <span class="text-primary">ACCESS CHINA</span> is the largest online platform connecting China and Western
          biopharma industry. We provide
          opportunities connecting the right partners for development, licensing, financing and commercial
          collaborations.
        </p>
        <p>
          We have successfully organized 6 online corporate access events 【<span
            class="text-primary"
          >ACCESS CHINA</span> Biotech Forum】and helped 300+ companies from 10 countries covering
          America, Asia, Europe, and China efficiently present their assets online in front of hundreds of big
          pharmaceutical companies and investors in China and the rest of the world.
        </p>
        <p>
          If you have pharma assets that are looking for partnering opportunities, including licensing-out, sell-out,
          etc., please submit your asset form to wxiang@yafocapital.com, we’ll contact you soon to discuss next steps.
        </p>
      </div>
    </div>
    <div class="share-container">
      <i
        class="fas el-icon-fa-share-alt"
        @click="copyShareUrl"
      />
    </div>
  </div>
</template>

<script>
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import * as AssetsAPI from '@/api/assets'
import _ from 'lodash'
import moment from 'moment'
import ElementTableHorizontalScrollbar from '@/mixin/ElementTableHorizontalScrollbar'

export default @Component({
  mixins: [ElementTableHorizontalScrollbar]
})
class Assets extends Vue {
  @Prop({ required: true }) type

  options = {
    phases: [],
    diseases: []
  }

  conditions = {
    disease: [],
    stage: [],
    created_at: [],
    code: '',
    keywords: ''
  }

  page = {
    current: 1,
    size: 20,
    total: 0
  }

  sort = {
    column: '',
    order: ''
  }

  assetsList = []

  pickerOptions = {
    shortcuts: [{
      text: this.$tc('last_week'),
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: this.$tc('last_month'),
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: this.$tc('last_quarter'),
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      }
    }]
  }

  // tableContainerHeight = 0

  get tableOffsetTop () {
    return this.$route.meta.statistical === 'normal' ? 60 : 0
  }

  get shareUrl () {
    const params = _.cloneDeep(this.conditions)
    if (this.conditions.created_at.length) {
      params.created_at = [moment(this.conditions.created_at[0]).format('YYYY-MM-DD'), moment(this.conditions.created_at[1]).format('YYYY-MM-DD')]
    }
    const router = this.$router.resolve({
      to: this.$route.name,
      query: _.pickBy(params, _.identity)
    })
    const {
      origin,
      pathname
    } = window.location
    return `${origin}${pathname}${router.href}`
  }

  getJSONData (data) {
    if (typeof data === 'string') {
      return JSON.parse(data)
    }
    return data
  }

  @Watch('type')
  handleTypeChange (type) {
    this.search(true)
  }

  mounted () {
    this.setCondition()
    this.getOptions()
    this.search()
    // this.ro = new ResizeObserver(this.onResize)
    // this.ro.observe(this.$refs.table_container)
  }

  // onResize () {
  //   this.tableContainerHeight = this.$refs.table_container?.offsetHeight
  //   this.$emit('resize', this.$refs.table_container?.offsetHeight)
  // }
  //
  // beforeDestroy () {
  //   this.ro?.unobserve(this.$refs.table_container)
  // }

  setCondition () {
    const params = this.$route.query
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(this.conditions, key)) {
        if (Array.isArray(this.conditions[key]) && !Array.isArray(params[key])) {
          this.conditions[key] = [params[key]]
        } else {
          this.conditions[key] = params[key]
        }
      }
    }
  }

  getOptions () {
    const { data } = AssetsAPI.getOptions()
    this.addTranslate(data)
    this.options = data
  }

  addTranslate (data) {
    const enTranslate = {}
    const zhTranslate = {}

    data.diseases.forEach(item => {
      enTranslate[item[0]] = item[0]
      zhTranslate[item[0]] = item[1]
    })

    this.$i18n.mergeLocaleMessage('zh', zhTranslate)
    this.$i18n.mergeLocaleMessage('en', enTranslate)
  }

  sortSearch ({
    prop,
    order
  }) {
    this.sort = {
      column: prop,
      order: {
        ascending: 'ASC',
        descending: 'DESC'
      }[order]
    }
    this.search()
  }

  async search (resetForm = false) {
    if (resetForm) {
      this.$refs.condition.resetFields()
      this.page.current = 1
    }
    const {
      current: page,
      size
    } = this.page

    const data = await Promise.all([
      AssetsAPI.getList({
        type: this.type,
        ...this.conditions,
        sort: this.sort,
        page: page - 1,
        size
      }),
      AssetsAPI.getSize({
        type: this.type,
        ...this.conditions
      })
    ])
    this.assetsList = data[0]
    this.page.total = data[1]
  }

  contact (data) {
    this.$router.push({
      name: 'Contact',
      params: {
        code: data.asset_code
      },
      query: {
        from: this.$route.meta.statistical
      }
    })
  }

  copyShareUrl () {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.shareUrl)
      this.$notify.success({
        message: this.$t('copy_share_url_success')
      })
    }
  }

  emptyFormatter (row, column) {
    const value = row[column.property]
    if (value === '0' || !value) {
      return '-'
    } else {
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.introduction {
  padding: 1rem 0;
  line-height: 1.5;
  font-size: 14px;
  color: #303133;

  p {
    margin: 0.5rem 0;
  }
}

.download {
  color: #db9620;
  text-decoration: underline #db9620;
}

.hot {
  width: 20px;
  margin-left: 5px;
}

.share-container {
  cursor: pointer;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  font-size: 1.8rem;
  z-index: 99999;
  color: #db9620;
}
</style>
